/* Showroom */
.box {
    background-color: #202823;
    min-height: 100vh;
    padding: 5%;
}

.imgContainer {
    position: relative;
}

.image {
    opacity: 1;
    display: block;
    width: 100%;
    height: 80%;
    border-radius: 20px;
    transition: .5s ease;
    backface-visibility: hidden;
}

.middle {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
}

.imgContainer:hover .image {
    opacity: 0.3;
}

.imgContainer:hover .middle {
    opacity: 1;
}

.showGrid {
    direction: row;
}

@media only screen and (max-width: 1400px) {}