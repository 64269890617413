/* Process */

.process {
    padding-top: 10%;
    height: 100%;
    padding-bottom: 10%;
    background-color: #89B43D;
}

.proc1 {
    opacity: 0;
    transform: translateY(100px);
    transition: opacity 3s, transform 2s;
}

.proc1.animate {
    opacity: 1;
    transform: translateY(0);
}

.proc2 {
    opacity: 0;
    transform: translateX(100px);

    transition: opacity 3s, transform 2s;
}

.proc2.animate {
    opacity: 1;
    transform: translateX(0);
}

.proc3 {
    opacity: 0;
    transform: translate(100px, 100px);
    transition: opacity 3s, transform 2s;
}

.proc3.animate {
    opacity: 1;
    transform: translate(0, 0);
}

.proc4 {
    opacity: 0;
    transform: translateY(100px);
    transition: opacity 3s, transform 2s;
}

.proc4.animate {
    opacity: 1;
    transform: translateY(0);
}

.proc5 {
    opacity: 0;
    transform: translate(-100px, -100px);
    transition: opacity 3s, transform 2s;
}

.proc5.animate {
    opacity: 1;
    transform: translate(0, 0);
}

.processBox {
    background-color: #202823;
    height: 400px;
    border-radius: 10px;
    border: 4px solid #202823;
    transition-duration: 1s;
}

.processBox:hover {
    background-color: transparent;
    box-shadow: 10px 10px;
}

.pBox {
    margin-left: 2%;
}

@media only screen and (max-width: 1400px) {
    .processBox {
        height: 400px;
    }

    .processBox h3 {
        padding-top: 0%;
        font-size: 50px;
    }

    .processBox .ptext {
        font-size: 20px;
    }

    .process {
        padding-top: 15%;
        margin-top: 15%;
        height: 100%;
        padding-bottom: 10%;
    }
}

@media only screen and (max-width: 600px) {
    .proc {
        margin-left: 2.5%;
    }

    .process {
        padding-top: 20%;
        margin-top: 15%;
        padding-bottom: 10%;
        height: 100%;
    }

    .processBox {
        height: 300px;
    }

    .processBox h3 {
        padding-top: 0%;
        font-size: 40px;
    }

    .processBox .ptext {
        font-size: 15px;
    }

    .pBox {
        margin-left: 10%;
    }
}