/* About */

.aboutHead {
    text-align: center;
    letter-spacing: 5px;
    opacity: 0;
    transform: translateY(100px);
    transition: opacity 5s ease, transform 5s ease;
}

.aboutHead.animate {
    opacity: 1;
    transform: translateY(0);
}

.aboutDesc {
    position: relative;
    height: 350px;
    background-color: #202823;
    width: 35%;
    margin-top: 5%;
    margin-left: 32.5%;
    margin-bottom: -8%;
}

@media only screen and (max-width: 1400px) {
    .aboutDesc {
        width: 85%;
        margin-left: 8%;
        margin-top: 10%;
        height: 300px;
    }
}

@media only screen and (max-width: 600px) {
    .aboutHead {
        letter-spacing: 0px;
    }

    .title {
        font-size: 10px;
    }

    .aboutDesc {
        width: 95%;
        margin-top: 50%;
        margin-left: 3%;
        height: 475px;
    }

    .storyButton {
        margin-bottom: 20px;
        margin-top: 50px;
    }
}