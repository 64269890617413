.logoImg {
  margin-top: 0%;
  margin-bottom: -1%;
  margin-left: 0%;
  margin-right: 0%;
  width: 100%;
}

.logoImgSmall {
  margin-top: 0%;
  margin-bottom: -1%;
  margin-left: 0%;
  margin-right: 0%;
  width: 100%;
  display: none;
}

/* Header */

.headerImg {
  margin-top: 60px;
  cursor: pointer;
  width: 125px;
}



/* Menu */

.miniMenu {
  display: none;
}

.largeMenu {
  display: flex;
}



@media only screen and (max-width: 1400px) {
  .miniMenu {
    display: block;
  }

  .largeMenu {
    display: none;
  }



  .headerImg {
    width: 110px;
    margin-top: 40px;
  }

  .logoImg {
    display: block;
  }

  .logoImgSmall {
    display: none;
  }
}

@media only screen and (max-width: 600px) {
  .process {
    padding-top: 15%;
    margin-top: 15%;
    padding-bottom: 10%;
    height: 100%;
  }

  .aboutHead {
    opacity: 0;
    width: 60%;
    transform: translateY(100px);
    transition: opacity 5s ease, transform 5s ease;
  }

  .logoImg {
    display: none;
  }

  .logoImgSmall {
    display: block;
  }
}



/* FONTS */


@font-face {
  font-family: Spicy;
  src: url('Spicy\ Style.ttf');
}

@font-face {
  font-family: ValeyCountry;
  src: url('Valley\ Country.otf');
}

@font-face {
  font-family: Biggy;
  src: url('Invisible-ExtraBold.otf');
}

@font-face {
  font-family: Mom;
  src: url('Happy\ Birthday\ Mom.otf');
}

@font-face {
  font-family: Rounded;
  src: url('Odin\ Rounded\ -\ Bold.otf');
}

@font-face {
  font-family: Romance;
  src: url('Aesthetic\ Romance.ttf');
}

@font-face {
  font-family: DevilBold;
  src: url('Devil\ Breeze\ Bold.ttf');
}

@font-face {
  font-family: DevilMedium;
  src: url('Devil\ Breeze\ Medium.ttf');
}

@font-face {
  font-family: DevilLight;
  src: url('Devil\ Breeze\ Light.ttf');
}

@font-face {
  font-family: Montserrat;
  src: url('Montserrat-VariableFont_wght.ttf');
}

/* Flip */
.front,
.back {
  position: absolute;
  background: #FEC606;
  top: 0;
  left: 0;
  width: 100px;
  height: 100px;
  border-radius: 5px;
  color: white;
  box-shadow: 0 27px 55px 0 rgba(0, 0, 0, 0.3), 0 17px 17px 0 rgba(0, 0, 0, 0.15);
  backface-visibility: hidden;
}

.front {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
}

.back {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
}

.card-container:hover .card {
  transform: rotateY(180deg);
}

.back {
  transform: rotateY(180deg);
}

.card-container {
  display: inline-block;
  margin: 0 auto;
  padding: 0 12px;
  perspective: 900px;
  text-align: center;
}

.card {
  position: relative;
  width: 100px;
  height: 100px;
  transition: all 0.6s ease;
  transform-style: preserve-3d;
}

/* Till Here */