/* Contact */

.contact {
    /* border-bottom: 5px solid #89B43D; */
}

.contact h4 {
    font-family: DevilBold;
    text-align: center;
    color: #89B43D;
    margin-top: 15%;
    margin-left: 55%;
    position: absolute;
}

.contactBox .contactStart {
    font-family: Romance;
    padding: 15px;
    font-weight: bold;
    font-size: 30px;
    color: #202823;
}

.contactBox {
    padding: 20px;
    border: 5px solid #202823;
    border-radius: 10px 80px;
    width: 40vw;
    height: 50%;
    margin: 0 20%;
}

.contactInfo {
    margin: 0 65%;
    width: 20vw;
    margin-top: 20%;
    position: absolute
}

.contactDiv {
    padding-top: 35%;
}

.contactBox.animate {
    animation-delay: 2s;
    animation: float 3s ease-in;
}

@media only screen and (max-width: 1400px) {
    .contactDiv {
        padding-top: 15%;
    }

    .contactInfo {
        width: 25vw;
        margin-top: 15%;
        position: absolute
    }

    .contact h4 {
        margin-top: 10%;
    }
}

@media only screen and (max-width: 600px) {
    .contactBox {
        padding: 20px;
        border: 5px solid #89B43D;
        border-radius: 10px 80px;
        width: 70vw;
        height: 50%;
        margin: 280% 20%;
    }

    .contactBox.animate {
        animation: none;
    }
    
    .contact h4 {
        margin-left: 8%;
        margin-right: 0%;
        margin-top: 2%;
        writing-mode: vertical-lr;
        height:300px;
        text-shadow: 2px -2px 2px #202823;
    }
    .contactInfo {
        padding: 0% 0%;
        margin: 0% 32%;
        width: 70vw;
        margin-top: 10%;
        /* background-color: white; */
        position: absolute
    }
}

/* Keyframes */

@keyframes float {
    from {
        margin: 0 -50%;
    }

    to {
        margin: 0 20%;
    }
}